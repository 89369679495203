<template>
    <div class="main__container main__padding column__center">
        <div class="imprint-box  ">
            <h2>Für Unternehmen</h2>

            <div class="header-3">
                Bei der It’s your Match finden Sie passende
                Ausbildungsplatzsuchende für Ihr Unternehmen.
            </div>
            <div>
                Die It’s your Match ermöglicht es allen Schülerinnen und
                Schülern ihren Einstieg ins Berufsleben zu finden.
            </div>
            <div>
                Registrieren sich sich als Aussteller und nutzen auch Sie die
                Möglichkeit den richtigen Match für Ihr Unternehmen zu finden.
            </div>
            <div>
                Dieses von der IHK Mittlerer Niederrhein betriebenes Portal ist
                kostenfrei!
            </div>
            <div>
                <br /><b> Der Ablauf für Aussteller</b><br /><b>
                    1. Registrierung:</b
                >
                Nach der Registrierung wird eine erste E-Mail zur
                E-Mail-Verifizierung versendet.<br /><b> 2. Freigabe:</b>

                Nach der Freigabe des Aussteller durch das It’s your Match Team
                wird eine E-Mail mit den Zugangsdaten versendet.<br />
            </div>
            <div>
                <b> 3. Bearbeitung der Ausstellerseite:</b> Über Über ein
                Online-Formular können die Informationen rund um ihr Unternehmen
                einfach erfasst werden. <br />

                <b> 4. Start der Aktion:</b> Ab dem 01.06.2021 wird das Online
                Portal und die dazugehörige It’s your Match App für Schüler
                freigeschaltet<br /><b>
                    5. Ausstellerprofil & Termin Verwaltung:</b
                >
                Verwalten Sie ihr Profil sowie alle gebuchten Gesprächstermine
                über den Aussteller Login.
            </div>
            <div class="row__start mt-20">
                <a href="https://admin.its-your-match.de/exhibitor/registration" class="button button--big mr-20">
                    <p class="text__white">Als Aussteller registrieren</p>
                </a>
                <a href="https://admin.its-your-match.de" class="button   button--reverse">
                    <p class="text__primary">Aussteller Login</p>
                </a>
            </div>

            <Footer class="w-100" />
        </div>
    </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
    components: { Footer },
    name: "ForCompanies",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>
